import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Checkout.css';
import { Container, Card, Button, Table, Row, Col } from 'react-bootstrap';
import { CartContext } from '../../Utils/CartContext';

function Placed() {
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchCartCount } = useContext(CartContext);

  const { orderId, totalAmount, items, address } = location.state || {};
  const baseUrl = 'https://theattarco.com/';

  useEffect(() => {
    const handlePopState = () => {
      navigate('/'); // Navigate to home page on back button press
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  useEffect(() => {
    fetchCartCount()
  }, []);

  const handleViewOrder = () => {
    // Extract orderId and email from formValues
    const { email } = address;

    // Navigate to order-track with orderId and email as state
    navigate('/track-order', { state: { preorderid: orderId, preemail: email } });
  };

  const handleHome = () => {
    // Navigate to home page 
    navigate('/');
  };


  return (
    <div>
      <section className='checkoutView pb-5'>
      <Container fluid className="px-lg-5 px-md-5 px-sm-5">
          <Card className="text-center mt-5 p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Title className='fs-2'>Order Placed Successfully!</Card.Title>
              <Card.Text>
                Thank you for placing your order. Your order ID is <strong>{orderId}</strong>.
              </Card.Text>
              <Card.Text>
                Total Amount Paid: <strong>&#8377;{totalAmount}</strong>
              </Card.Text>
              <Row>
                <Col md={6}>
                  <Card.Text className='text-start pt-2 mb-0 text-black'>
                    <strong>Shipping Address:</strong>{address.firstName + ' ' + address.lastName}</Card.Text>
                  <Card.Text className='text-start py-0 mb-0'>{address.address}</Card.Text>
                  <Card.Text className='text-start py-0 mb-0'>{address.address2}</Card.Text>
                  <Card.Text className='text-start py-0 mb-0'>{address.city}, {address.state} - {address.zip}</Card.Text>
                  <Card.Text className='text-start py-0 mb-0'>{address.phone}</Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Text className='text-end pt-2 mb-0 text-black'>
                    <strong>Billing Address:</strong>{address.firstName + ' ' + address.lastName}</Card.Text>
                  <Card.Text className='text-end py-0 mb-0'>{address.address}</Card.Text>
                  <Card.Text className='text-end py-0 mb-0'>{address.address2}</Card.Text>
                  <Card.Text className='text-end py-0 mb-0'>{address.city}, {address.state} - {address.zip}</Card.Text>
                  <Card.Text className='text-end py-0 mb-0'>{address.phone}</Card.Text>
                </Col>
              </Row>
              <Card.Text>
                <strong>Order Details:</strong>
              </Card.Text>
              <Table striped bordered hover>
                <tbody>
                  {items && items.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img src={baseUrl + item.images[0]} alt={item.prod_name} style={{ width: '50px', height: '50px' }} />
                      </td>
                      <td className='text-start'>
                        {item.name}<br />
                        Quantity : {item.qty}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
            <div className='d-flex justify-content-around'>
              <Button variant="dark" className='w-100 me-2' onClick={handleViewOrder}>View Order Details</Button>
              <Button variant="danger" className='w-100 ms-2' onClick={handleHome}>Go to Home</Button>
            </div>
          </Card>
        </Container>
      </section>
    </div>
  );
}

export default Placed;
