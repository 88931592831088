import React, { useState, useEffect, useContext } from 'react';
import { Container, Navbar, Nav, Form, FormControl, InputGroup, Dropdown, DropdownButton, Button, Badge, Row, Col, Modal } from 'react-bootstrap';
import './Header.css';
import { IoBagHandleOutline, IoCalendarClearOutline, IoCallOutline, IoCartOutline, IoHeartOutline, IoMenuOutline, IoMenuSharp, IoPersonOutline, IoSearchOutline } from "react-icons/io5";
import logoImage from '../../assets/images/brand.png';
import logoImageBlack from '../../assets/images/brandblack.png';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaCalendar, FaFacebook, FaFacebookF, FaInstagram, FaTimes, FaWhatsapp } from 'react-icons/fa';
import { TbBrandGoogleMaps } from 'react-icons/tb';
import { AiFillTruck, AiFillYoutube, AiOutlineMail, AiOutlineTruck, AiOutlineWhatsApp } from 'react-icons/ai';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import SecureLS from "secure-ls";

function Header() {
    const { cartCount, cartTotal } = useContext(CartContext);
    const { wishlistCount } = useContext(WishlistContext); // Use wishlist context

    const [iconColor, setIconColor] = useState('white');
    const [searchInput, setSearchInput] = useState('');

    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchInput != '') {
            navigate(`/search/${searchInput}`, {
                state: {
                    searchInput
                }
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset;
            if (currentScrollTop > lastScrollTop && currentScrollTop > 1 * window.innerHeight / 100) {
                setIsNavbarVisible(false);
            } else {
                setIsNavbarVisible(true);
            }
            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    // useEffect(() => {
    //     const headerElement = document.querySelector('.custom-header');

    //     if (isNavbarVisible) {
    //         headerElement.style.top = '0'; // Show the header
    //     } else {
    //         headerElement.style.top = '-38px'; // Hide the header, adjust the value based on header height
    //     }
    // }, [isNavbarVisible]);


    const [categories, setCategories] = useState([])
    const baseUrl = 'https://theattarco.com/'

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            const response = await axios.get('https://theattarco.com/api/client_api/productCategories/index.php');
            setCategories(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    // Initialize SecureLS
    const ls = new SecureLS({ encodingType: 'aes' });

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        // ls.removeAll();
        // Retrieve the stored email and name using SecureLS
        const email = ls.get('userEmail');
        const name = ls.get('userName');

        if (email && name) {
            setIsLoggedIn(true);
            setUserName(name);
        }

    }, []);

    const SignInLink = () => {
        const location = useLocation();

        const handleSignInClick = () => {
            // Store the current path in sessionStorage
            sessionStorage.setItem('redirectPath', location.pathname);
        };

        return (
            <Link
                to='/auth/login'
                className='text-decoration-none text-black d-flex align-items-center justify-content-center'
                onClick={handleSignInClick} // Call this function on click
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#fff' className="icon" />
                </div>
            </Link>
        );
    };


    const UserDropdown = () => {
        return (
            <Link
                to='/account'
                className='text-decoration-none text-black d-flex align-items-center justify-content-center'
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#fff' className="icon" />
                </div>
            </Link>
        );
    };

    const handleMenuClick = () => {
        setSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        // Close the sidebar first
        handleSidebarClose();

        // After closing the sidebar, show the logout confirmation modal
        setTimeout(() => {
            setShow(true);
        }, 300); // Adjust the delay if necessary
    };

    const handleLogout = async () => {
        try {
            // Send the POST request to the logout endpoint
            const response = await axios.post('https://theattarco.com/api/client_api/authentication/logout/index.php');

            // Check if the response indicates success
            if (response.data.success) {
                // Remove user data from SecureLS
                ls.remove('userEmail');
                ls.remove('userName');

                // Update state to reflect logout
                setIsLoggedIn(false);
                setUserName('');

                // Redirect to home page
                window.location.href = 'https://theattarco.com';
            } else {
                // Handle the case where the server response is not successful
                alert('Error logging out, please try again');
            }
        } catch (error) {
            // Handle any errors during the request
            console.error('Error logging out:', error);
            alert('Error logging out: Please try again.');
        }
    };


    return (
        <div>
            <header className={`custom-header ${isNavbarVisible ? '' : 'hidden'}`}>
                <div style={{ height: 'auto', borderBottom: "1px solid #DDDDDD" }} className={`py-0 header-top menu-navbar`}>
                    <Container fluid className='px-lg-5 px-md-5 px-sm-5'>
                        <Row className="align-items-center">
                            <Col xs={12} lg={4} sm={4} md={3} className="p-0">
                                <div className="social-media d-flex">
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://www.facebook.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebookF color="#000000" size={20} />
                                    </a>
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://www.instagram.com/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaInstagram color="#000000" size={20} />
                                    </a>
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://wa.me/918129616717?text=Hello there, I am looking to know more. Pleas call me"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaWhatsapp color="#000000" size={20} />
                                    </a>
                                </div>
                            </Col>
                            <Col xs={12} lg={8} sm={8} md={9} className="p-0 d-flex justify-content-end">
                                <div className="d-flex flex-row align-items-center p-2">
                                    <AiOutlineTruck color="#000000" size={20} />
                                    <p className="text-start text-capitalize mb-0 ms-1 header-top-text">Free Shipping across India</p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2">
                                    <AiOutlineMail color="#000000" size={20} />
                                    <p className="text-start mb-0 ms-1 header-top-text" >
                                        <a
                                            href="mailto:info@theattarco.com"
                                            className="text-decoration-none text-black"
                                        >
                                            info@theattarco.com
                                        </a>
                                    </p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2">
                                    <IoCallOutline color="#000000" size={20} />
                                    <p className="text-start mb-0 ms-1 header-top-text"
                                    >
                                        <a
                                            href="tel:8129616717"
                                            className="text-decoration-none text-black"
                                        >
                                            (+91) 81296 16717
                                        </a>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='top-header px-lg-5 px-md-5 px-sm-5'>
                    <Container fluid className="d-flex align-items-center justify-content-between">
                        <div className="logo" style={{ flexBasis: '10%' }}>
                            <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                <Link to="/">
                                    <img
                                        src={logoImage}
                                        width="auto"
                                        height="50"
                                        className="d-inline-block align-top"
                                        alt="Karstar"
                                    />
                                </Link>
                            </Navbar.Brand>
                        </div>
                        <div className='d-none justify-content-center align-items-center d-lg-flex' style={{ flexBasis: '80%' }}>
                            <Navbar id="basic-navbar-nav">
                                <Nav className="me-auto text-white">
                                    <Nav.Link as={Link} to="" className="text-white fw-light me-2 bottomNavbarCategories">Home</Nav.Link>
                                    <Nav.Link as={Link} to="collection/All-Products" className="text-white fw-light me-2 bottomNavbarCategories">All Pefumes</Nav.Link>
                                    <Nav.Link as={Link} to="aboutus" className="text-white fw-light me-2 bottomNavbarCategories">About Us</Nav.Link>
                                    <Nav.Link as={Link} to="contactus" className="text-white fw-light me-2 bottomNavbarCategories">Contact Us</Nav.Link>
                                    <Nav.Link as={Link} to="store-locator" className="text-white fw-light me-2 bottomNavbarCategories">Store Locator</Nav.Link>
                                    <Nav.Link as={Link} to="track-order" className="text-white fw-light me-2 bottomNavbarCategories">Track Order</Nav.Link>
                                </Nav>
                            </Navbar>
                        </div>
                        <div className="d-flex align-items-center justify-content-end" style={{ flexBasis: '10%' }}>
                            <div className='d-flex'>
                                <Link to='search' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoSearchOutline size={'22px'} color='#FFF' className="icon" />
                                    </div>
                                </Link>
                                {isLoggedIn ? <UserDropdown /> : <SignInLink />}
                                <Link to='Wishlist' className='text-decoration-none d-none d-lg-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoHeartOutline size={'22px'} color='#FFF' className="icon" />
                                        <span className='icon-badge wishlist-badge fw-bold'> {wishlistCount} </span>
                                    </div>
                                </Link>
                                <Link to='Cart' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoBagHandleOutline size={'22px'} color='#FFF' className="icon" />
                                        <span className='icon-badge cart-badge fw-bold'> {cartCount} </span>
                                    </div>
                                </Link>
                                <div className='text-decoration-none d-flex d-lg-none align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoMenuSharp size={'22px'} color='#FFF' className="icon"
                                            onClick={() => handleMenuClick()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </header>

            {/* Backdrop */}
            {(isSidebarOpen) && (
                <div className="backdrop show" onClick={handleSidebarClose}></div>
            )}

            {/* Menu Sidebar */}
            <aside className={`wrap-sidebar js-sideorderbar ${isSidebarOpen ? 'show-sidebar' : ''}`}>
                <div className="sidebar p-t-22 p-b-25">
                    <div className="d-flex justify-content-between align-items-center " style={{ position: 'sticky', top: 0, zIndex: 1, background: "#fff" }}>
                        <div className="text-dark mobile-menu-toggle-icon f12 fw-bold">
                            <div className="logo" style={{ flexBasis: '10%' }}>
                                <Navbar.Brand className='d-flex justify-content-center align-items-center'>
                                    <Link to="/">
                                        <img
                                            src={logoImageBlack}
                                            width="auto"
                                            height="50"
                                            className="d-inline-block align-top"
                                            alt="Karstar"
                                        />
                                    </Link>
                                </Navbar.Brand>
                            </div>
                        </div>
                        <div className="mobile-menu-toggle-icon js-hide-sideordertrackbar" onClick={handleSidebarClose}>
                            <FaTimes size={'22px'} color='#000' className="icon" />
                        </div>
                    </div>
                    <div className="dropdown px-5">
                        <div className="dropdown-options py-2">
                            <Link to="/">
                                Home
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="collection/All-Products">
                                All Pefumes
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="aboutus">
                                About Us
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="contactus">
                                Contact Us
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="store-locator">
                                Store Locator
                            </Link>
                        </div>
                        <div className="dropdown-options py-2">
                            <Link to="track-order">
                                Track Order
                            </Link>
                        </div>
                    </div>
                </div>
            </aside>

            {/* Logout Confirmation Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton className='border-0'>
                    <Modal.Title>Confirm Logout</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to log out?</Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="dark" onClick={handleLogout}>
                        Logout
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Header;
