import React from 'react'
import './Cart.css'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
function NoCartItems() {
    return (
        <div>
            <section className="pricing-list-section container py-5" id="bucket">
                <Container>
                    <div
                        className="empty-bag"
                        style={{
                            height: "20vh",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: 'center'
                        }}
                    >
                        <div className="empty-img">
                            <img src="./images/empty.png" alt="" width="15%" />
                        </div>
                        <div className='w-100'>
                            <h5 className="f24 fw-bold lh-1 mb-3 blue text-white">
                                Your Cart is Currently Empty!
                            </h5>
                            <p className="f14 text-center text-light">
                                You are on a right track, only products are missing to checkout. Please select from suggested products or go back to home page to add products
                            </p>
                            <div className="d-grid gap-2 d-flex justify-content-center">
                                <Link
                                    to="/"
                                >
                                    <Button style={{ borderColor: '#000000', borderWidth: '1px', background: '#ffffff' }}
                                        type='submit'
                                        className='d-flex justify-content-center align-items-center rounded-5 text-black fw-bold px-4 py-2'
                                    >
                                        Take me to home
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}

export default NoCartItems
