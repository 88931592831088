import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom';
import CategoriesProductCard from '../../components/ProductCard/CategoriesProductCard';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import ProductCard from '../../components/ProductCard/ProductCard';
import './Product.css'
function ProductCategories() {

    const { categoryName } = useParams();
    const baseUrl = 'https://theattarco.com/';

    const formatTitle = (slug) => {
        return slug
            .split('-')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    useEffect(() => {
        if (categoryName) {
            const formattedTitle = formatTitle(categoryName);
            document.title = `${formattedTitle} | Karstar`;
            getCategoryProducts(categoryName);
        }
    }, [categoryName]);


    const [products, setProducts] = useState([])

    const getCategoryProducts = async (categoryName) => {
        try {
            const response = await axios.get(`https://theattarco.com/api/client_api/categorizedProducts/?category=${categoryName}`);
            setProducts(response.data);

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <Container>
                <section className='ProductCategoryView'>
                    <div className='breadcrumb-container pt-5'>
                        <div className='breadcrumb'>
                            <Link to='/' className='breadcrumb-item text-black'>Home</Link>
                            <span className='breadcrumb-separator'>/</span>
                            <Link to='/products' className='breadcrumb-item text-black'>Products</Link>
                            <span className='breadcrumb-separator'>/</span>
                            <div className='breadcrumb-item text-black active'>{formatTitle(categoryName)}</div>
                        </div>
                    </div>
                    <Row className="g-0">
                        {products && products.map((item, index) => (
                            <Col xs={6} md={6} lg={2} key={index}>
                                <ProductCard item={item} baseUrl={baseUrl} />
                            </Col>
                        ))}
                    </Row>
                </section>
            </Container>
        </div>
    )
}

export default ProductCategories
