import React, { useEffect, useState, useRef } from 'react';
import './VideoPopup.css';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import introvideo from '../../assets/videos/intro_the_attar_co.mp4';

const VideoPopup = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const videoRef = useRef(null); // Reference to the video element

    useEffect(() => {
        const isVideoShown = localStorage.getItem('videoShown');
        if (!isVideoShown) {
            setTimeout(() => {
                setIsPopupOpen(true);
            }, 5000); // Show popup after 1 second
            localStorage.setItem('videoShown', 'true');
        }
    }, []);

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    return (
        <>
            <div className={`video-popup-overlay ${isPopupOpen ? 'show' : ''}`} onClick={closePopup}></div>
            <div className={`video-popup-container ${isPopupOpen ? 'open' : ''}`}>
                <div className="video-popup-content">
                    <video autoPlay loop muted>
                        <source src={introvideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </>
    );
};

export default VideoPopup;
