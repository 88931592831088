import React, { useEffect } from 'react';
import './Aboutus.css';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Import images statically
import vision1 from '../../assets/images/IMG_0418.JPG';
import vision2 from '../../assets/images/IMG_0430.JPG';
import mission1 from '../../assets/images/IMG_0418.JPG';
import mission2 from '../../assets/images/IMG_0430.JPG';

function Aboutus() {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [navigate]);

  return (
    <Container fluid className='sectionView mt-3 px-lg-5 px-md-5 px-sm-5'>
      <div className='breadcrumb-container'>
        <div className='breadcrumb'>
          <a href='/' className='breadcrumb-item text-white'>Home</a>
          <div className='breadcrumb-item text-white'>About Us</div>
        </div>
      </div>

      <section className="bg-img1 text-center text-white p-lr-15 p-b-10 p-t-95 container">
        <h2 className="ltext-103 text-white text-center cl4" style={{ margin: '0 15px 30px 15px' }}>About Us</h2>
      </section>

      {/* Our Vision Section */}
      <section className="p-t-5 p-b-120">
        <div className="row pb-5">
          <div className="col-md-7 col-lg-8">
            <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
              <h3 className="mtext-111 cl2 text-white p-b-16">Our Vision</h3>
              <p className="stext-113 text-white cl6 p-b-26">
                To become the leading global brand in the world of perfumery, renowned for delivering the finest quality attar oils and perfumes, and inspiring a deep appreciation for the art of scent.
              </p>
              <p className="stext-113 text-white cl6 p-b-26">
                We started with [initial product/service] and expanded to include [additional products/services].
              </p>
            </div>
          </div>
          <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
            <div className="d-flex justify-content-between position-relative">
              <img src={vision1} alt="Vision Image 1" className='img-instagram-style' />
              <img src={vision2} alt="Vision Image 2" className='img-instagram-style' style={{
                position: 'absolute',transform: 'rotate(20deg)',left: '140px'
              }} />
            </div>
          </div>
        </div>

        {/* Our Mission Section */}
        <div className="row py-5">
          <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
            <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
              <h3 className="mtext-111 text-white cl4 p-b-16">Our Mission</h3>
              <p className="stext-113 text-white cl6 p-b-26">
                To craft and curate exceptional attars and perfumes that resonate with the passion and tradition of perfumery, while empowering fragrance enthusiasts through franchise opportunities to bring the love for high-quality scents to new markets and communities.
              </p>
            </div>
          </div>
          <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
            <div className="d-flex justify-content-between position-relative">
              <img src={mission1} alt="Mission Image 1" className='img-instagram-style' style={{
                position: 'absolute',transform: 'rotate(20deg)',left: '140px'
              }} />
              <img src={mission2} alt="Mission Image 2" className='img-instagram-style' style={{zIndex:'1'}} />
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
}

export default Aboutus;
