import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const AccessibilityPolicy = () => {
  return (
    <Container className="accessibility-policy sectionView">
      <Row>
        <Col>
          <h1 className="text-center">Accessibility Policy</h1>
          <p className="text-center"><em>Last updated: 1st September 2024</em></p>
          
          <h2>1. Accessibility Commitment</h2>
          <p>The Attar Co. ("we," "us," "our") is committed to ensuring that our website is accessible to individuals with disabilities. We strive to adhere to applicable accessibility standards and guidelines, including the Web Content Accessibility Guidelines (WCAG).</p>
          
          <h2>2. Accessible Design</h2>
          <p>We are continuously working to make our website more accessible by implementing accessible design and usability features.</p>
          
          <h2>3. Feedback and Assistance</h2>
          <h3>a. Feedback</h3>
          <p>If you encounter any accessibility issues while using our website, please provide feedback so that we can address the issue promptly.</p>
          
          <h3>b. Assistance</h3>
          <p>If you require assistance or have specific accessibility needs, please contact our customer support team for assistance.</p>
          
          <h2>4. Contact Us</h2>
          <p>If you have any questions or concerns about our Accessibility Policy or require assistance with accessibility features, please contact us from the contact us page.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default AccessibilityPolicy;
