import React, { useEffect, useState } from 'react';
import './Collections.css';
import axios from 'axios';
import Slider from 'react-slick';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProductCard from '../../components/ProductCard/ProductCard';
import { useNavigate } from 'react-router-dom';

function Collections() {
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);


    const productsSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const [collectionItems, setCollectionItems] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchProduct();
    }, []);

    const baseURL = 'https://theattarco.com/';

    const fetchProduct = async () => {
        try {
            const response = await axios.get(`${baseURL}api/client_api/collections/index.php`);
            const fetchedItems = response.data;
            console.log(fetchedItems);
            setCollectionItems(fetchedItems);
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const renderCollectionSection = (collectionName, items) => (
        <section key={collectionName}>
            <Row className="g-sm-2 g-lg-4 g-md-4">
                {/* <Col> */}
                <h4 className='title text-white py-2'>Shop By {collectionName}</h4>
                {isLoading ? (
                    <div>Loading...</div> // Display a loading message or spinner
                ) : (
                    // <Slider {...productsSettings} className="top-categories pt-2">
                    items.map((item, index) => (
                        <Col lg={3} md={4} sm={6} xs={6} key={index}>
                            <ProductCard
                                key={index}
                                item={item}
                                baseUrl={baseURL}
                                wishlist={item.price.some(price => price.in_wishlist)}
                                cart={item.price.some(price => price.in_cart)}
                            />
                        </Col>
                    ))
                    // </Slider>
                )}
                {/* </Col> */}
            </Row>
        </section>
    );

    return (
        <div className='sectionView container-fluid mt-3 px-lg-5 px-md-5 px-sm-5'>
            <div className='breadcrumb-container'>
                <div className='breadcrumb'>
                    <a href='/' className='breadcrumb-item text-white'>Home</a>
                    <div className='breadcrumb-item text-white'>Collections</div>
                </div>
            </div>
            {error && <div>{error}</div>}
            {Object.keys(collectionItems).map(collectionName =>
                renderCollectionSection(collectionName, collectionItems[collectionName])
            )}
        </div>
    );
}

export default Collections;
