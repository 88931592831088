import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const ShippingPolicy = () => {
  return (
    <Container className="shipping-delivery-policy  sectionView">
      <Row>
        <Col>
          <h1 className="text-center">Shipping and Delivery Policy</h1>
          <p className="text-center"><em>Last updated: 1st September 2024</em></p>
          
          <h2>1. Shipping Methods</h2>
          <p>We offer various shipping methods for your convenience, including those provided by Professional Couriers.</p>
          
          <h2>2. Estimated Delivery Times</h2>
          <p>Estimated delivery times are provided for reference and may vary based on location and other factors. Please refer to the estimated delivery timeframe provided during the checkout process. Our standard delivery times range from a minimum of 3 working days to a maximum of 7 working days.</p>
          
          <h2>3. Delivery Issues</h2>
          <p>In the event of any delivery issues, including delays or damage during transit, please contact our customer support team for prompt assistance.</p>
          
          <h2>4. Contact Us</h2>
          <p>If you have any questions or concerns about our Shipping and Delivery Policy, please contact us from the contact us page.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ShippingPolicy;
