import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { BsTruck } from "react-icons/bs";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GoShieldCheck } from "react-icons/go";
import { IoArrowForward, IoStorefrontOutline } from "react-icons/io5";
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const StoreInfo = () => {

    const navigate = useNavigate(); // For redirecting to another screen

    const [storeinfo, setStoreinfo] = useState()

    useEffect(() => {
        getStoreInfo();
    }, [])

    const getStoreInfo = async () => {
        try {
            const response = await axios.get('https://theattarco.com/api/client_api/StoresInfo/index.php');
            setStoreinfo(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const gotoStores = () => {
        navigate('/store-locator'); // Redirects to the login screen
    };

    return (
        <div style={{ background: '#000000', }} className='my-2'>
            <Container className="my-5">
                <Row className="text-center justify-content-center">
                    <Col md={6} sm={12} className='d-flex flex-column flex-md-column align-items-center justify-content-center my-4'>
                        <div className='text-start text-md-start mx-2 mt-1'>
                            <h3 className="my-0 text-uppercase text-white">{storeinfo?.largeText}</h3>
                            <Button onClick={gotoStores} variant="light" className='rounded-5 py-2 mt-4' style={{ display: 'flex', alignItems: 'center', background: '#000', border: '4px solid #f7ef8a' }}>
                                <Card.Title className='text-start mb-0 fw-bold' style={{ fontSize: '18px', color: '#f7ef8a' }}>
                                    {storeinfo?.buttonText}
                                </Card.Title>
                                <IoArrowForward className="ms-2" color='#f7ef8a' />
                            </Button>
                        </div>
                    </Col>
                    <Col md={6} sm={12} className='d-flex flex-column flex-md-column align-items-start justify-content-start my-4'>
                        <div className='text-start text-md-start mx-2 mt-1'>
                            <p className='mb-0 text-white' style={{ fontSize: '1rem' }}>{storeinfo?.descText}</p>
                            <h4 className="my-2  text-uppercase gradient-text">{storeinfo?.gradientText}</h4>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default StoreInfo;
