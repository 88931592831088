import React, { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import './CouponCodeSlider.css';

function CouponCodeSlider() {
    const [direction, setDirection] = useState('left');
    const [speed, setSpeed] = useState(30);
    const [isScrolling, setIsScrolling] = useState(false);
    const scrollTimeoutRef = useRef(null);

    useEffect(() => {
        const handleScroll = (e) => {
            const deltaY = e.deltaY;
            const newDirection = deltaY > 0 ? 'left' : 'right';
            const newSpeed = Math.min(Math.abs(deltaY) * 0.5, 100); // Adjust speed scaling as necessary

            setDirection(newDirection);
            setSpeed(newSpeed);
            setIsScrolling(true);

            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }

            scrollTimeoutRef.current = setTimeout(() => {
                setSpeed(30);
                setIsScrolling(false);
            }, 500);
        };

        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
            if (scrollTimeoutRef.current) {
                clearTimeout(scrollTimeoutRef.current);
            }
        };
    }, []);

    return (
        <div className="marquee-container">
            <Marquee
                pauseOnHover
                gradient={false}
                direction={direction}
                autoFill
            >
                <div className="sliding-text">USE CODE TACLC2024 & GET DISCOUNT</div>
            </Marquee>
        </div>
    );
}

export default CouponCodeSlider;
