import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Container, Row, Form, Button } from 'react-bootstrap';
import './MyOrders.css';
import moment from 'moment';
import { FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import SecureLS from "secure-ls";

function MyOrders() {
    const ls = new SecureLS({ encodingType: 'aes' });
    const location = useLocation();
    const [orderData, setOrderData] = useState([]);
    const [isTrackSidebarOpen, setTrackSidebarOpen] = useState(false);
    const [isDetailsSidebarOpen, setDetailsSidebarOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const baseURL = 'https://theattarco.com/';

    useEffect(() => {
        // This effect is now only responsible for managing the body scroll class
        if (isTrackSidebarOpen || isDetailsSidebarOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isTrackSidebarOpen, isDetailsSidebarOpen]);

    useEffect(() => {
        fetchOrders();
    },[])


    const fetchOrders = async (e) => {
        try {
            const email = ls.get('userEmail');
            const OrderData = { email };
            const response = await axios.post('https://theattarco.com/api/client_api/Orders/MyOrders/index.php', OrderData, {
                headers: { 'Content-Type': 'application/json' }
            });
            setOrderData(response.data.data);
        } catch (error) {
            console.error('Error fetching order data:', error);
            // Optionally, show user feedback here
        }
    };

    const handleTrackClick = (order) => {
        setSelectedOrder(order);
        setTrackSidebarOpen(true);
    };

    const handleDetailsClick = (order) => {
        setSelectedOrder(order);
        setDetailsSidebarOpen(true);
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('MMMM D[,] h:mm A');
    };

    const handleSidebarClose = () => {
        setTrackSidebarOpen(false);
        setDetailsSidebarOpen(false);
    };

    return (
        <div className='sectionView mb-5 mt-3'>
            <Container fluid className='px-lg-5 px-md-5 px-sm-5'>
                <div className='breadcrumb-container'>
                    <div className='breadcrumb'>
                        <a href='/' className='breadcrumb-item text-white'>Home</a>
                        <div className='breadcrumb-item text-white'>My Orders</div>
                    </div>
                </div>
                <div>
                    <Row>
                        {orderData.map((item, index) => (
                            <Col md={4} key={index}>
                                <Card onClick={() => handleTrackClick(item)} className="text-center w-100 h-100 border-0" style={{ padding: 0, cursor: 'pointer' }}>
                                    <Card.Header className="text-muted bg-transparent border-bottom-0">
                                        <Row>
                                            <Col sm={12} className="d-flex justify-content-between mb-2">
                                                <div className="d-flex flex-column me-3 justify-content-start align-items-start">
                                                    <p className="mb-0 text-start f12 fw-bold">Order Placed</p>
                                                    <p className="mb-0 text-start f14">{formatDate(item?.order_date)}</p>
                                                </div>
                                                <div className="d-flex flex-column me-3 justify-content-start align-items-start">
                                                    <p className="mb-0 text-start f12 fw-bold">Order ID</p>
                                                    <p className="mb-0 text-start f14">{item.orderid}</p>
                                                </div>
                                                <div className="d-flex flex-column me-3 justify-content-start align-items-start">
                                                    <p className="mb-0 text-start fw-bold f12">Order Status</p>
                                                    <p className="mb-0 text-start f14">
                                                        <span className="p-2 badge bg-dark">{item.order_status[item.order_status.length - 1]?.status}</span>
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className="text-muted bg-transparent">
                                        <Row className="pb-3">
                                            <Col sm={12} className="mb-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="orderimage">
                                                        <img
                                                            src={baseURL + item.prod_image.replace(/^\.\.\.\.\//, '')}
                                                            alt="Product Image"
                                                            style={{ borderRadius: '15px', width: '60px', height: '60px', marginRight: '10px', objectFit: 'fill' }}
                                                        />
                                                    </div>
                                                    <div className="user-details">
                                                        <p className="mb-0 text-start fw-bold">{item.prod_name}</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
                {/* Tracking Sidebar */}
                <aside className={`wrap-sidebar js-sideordertrackbar ${isTrackSidebarOpen ? 'show-sidebar' : ''}`}>
                    <div className="sidebar p-t-22 p-b-25">
                        <div className="d-flex justify-content-between align-items-center " style={{ position: 'sticky', top: 0, zIndex: 1, background: "#fff" }}>
                            <div className="text-dark mobile-menu-toggle-icon f12 fw-bold">
                                Tracking Details of &nbsp;<span>{selectedOrder?.orderid}</span>
                            </div>
                            <div className="mobile-menu-toggle-icon js-hide-sideordertrackbar" onClick={handleSidebarClose}>
                                <FaTimes size={'22px'} color='#000' className="icon" />
                            </div>
                        </div>
                        <div className="sidebar-menu-content">
                            <div id="ordertrack" className='w-100'>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <Card className="w-100  d-flex">
                                            <h6 className="text-start px-3 py-2 text-dark">Order Status Updates</h6>
                                            <div className="card-body d-flex flex-column pt-0">
                                                <div className="timeline">
                                                    {selectedOrder?.order_status.map((item, index) => (
                                                        <div className={`tl-item ${index === selectedOrder.order_status.length - 1 ? 'active' : ''}`} key={index}>
                                                            <div className="tl-dot b-warning"></div>
                                                            <div className="tl-content">
                                                                <h6 className="text-start blue text-dark">{item.status}</h6>
                                                                <div className='text-start'>{item.desc}</div>
                                                                <div className="tl-date text-start text-muted mt-1">{formatDate(item?.updated_at)}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <Card className="h-100 w-100  d-flex">
                                            <h6 className="text-start px-3 py-2 text-dark">Order Summary</h6>
                                            <Card.Body className="d-flex flex-column pt-0">
                                                <div className="cart_info">
                                                    <div className="row">
                                                        <div className="col-sm-12 align-items-start">
                                                            <div className="d-flex justify-content-between">
                                                                <p className="text-start mb-0">Quantity</p>
                                                                <p className="text-end mb-0">{selectedOrder?.prod_qty}</p>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="text-start mb-0">Subtotal</p>
                                                                <p className="text-end mb-0">
                                                                    ₹ {selectedOrder?.prod_Price * selectedOrder?.prod_qty}</p>
                                                            </div>
                                                            <div className="d-flex justify-content-between">
                                                                <p className="text-start mb-0">Shipping</p>
                                                                <p className="text-end iscouponapplied  mb-0">₹ {selectedOrder?.delivery_charges}</p>
                                                            </div>
                                                            <hr />
                                                            <div className="d-flex justify-content-between">
                                                                <p className="text-start  mb-0">Amount to Pay</p>
                                                                <p className="text-end  mb-0">₹ {(selectedOrder?.prod_Price * selectedOrder?.prod_qty) + selectedOrder?.delivery_charges}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <Card className="h-100 w-100  d-flex">
                                            <h6 className="text-start px-3 py-2 text-dark">Order Details</h6>
                                            <div className="card-body d-flex flex-column pt-0">
                                                <h6 className="text-start blue f16">Customer Information</h6>
                                                <p className="mb-0 text-start pb-0">Email: {selectedOrder?.email}</p>
                                                <p className="mb-0 text-start pb-3">Shipping Address: {selectedOrder?.address1 + ', ' + selectedOrder?.address1 + ', ' + selectedOrder?.city + ', ' + selectedOrder?.zip + ', ' + selectedOrder?.states}</p>
                                                <h6 className="text-start blue f16">Product Details</h6>
                                                <div className="mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="orderimage">
                                                            <img
                                                                src={baseURL + selectedOrder?.prod_image.replace(/^\.\.\.\.\//, '')} // Removing ../../../../ from image URL
                                                                alt="Product Image"
                                                                style={{ borderRadius: '15px', width: '60px', height: '60px', marginRight: '10px', objectFit: 'fill' }}
                                                            />
                                                        </div>
                                                        <div className="user-details">
                                                            <p className="mb-0 text-start fw-bold">{selectedOrder?.prod_name}</p>
                                                            <p className="mb-0 text-start f14">Quantity: {selectedOrder?.prod_qty}</p>
                                                            <p className="mb-0 text-start f14">Price: {selectedOrder?.prod_Price}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </Container>
        </div>
    );
}

export default MyOrders;
