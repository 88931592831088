import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
// import './Wishlist.css';
import ProductCard from '../../components/ProductCard/ProductCard';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineHome } from 'react-icons/ai';
import axios from 'axios';
function CollectionView() {
    const { collectionName } = useParams();
    const collection_name = collectionName.replace(/-/g, ' ');
    const baseURL = 'https://theattarco.com/';
    const navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToTop();
    }, [navigate]);

    const [collectionItems, setCollectionItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = sessionStorage.getItem('username');
        if (username == null) {
            if (sessionId !== null) {
                fetchProduct(sessionId, 'na', collection_name);
            }
        } else {
            fetchProduct('na', username, collection_name);
        }
    }, [collection_name]);


    const fetchProduct = async (ipadd, email, collectionName) => {
        try {
            const response = await axios.get(`${baseURL}api/client_api/collections/getCollections.php`, {
                params: {
                    name: collectionName,
                    ipadd: ipadd,
                    email: email,
                }
            });
            const fetchedItem = response.data.data;
            setCollectionItems(fetchedItem);
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };


    const Breadcrumb = ({ product }) => {
        return (
            <div className='breadcrumb-container'>
                <div className='breadcrumb'>
                    <a href='/' className='breadcrumb-item text-white me-2'><AiOutlineHome size={20} /></a>
                    <a href='/collections' className='breadcrumb-item text-white me-2'>Collections</a>
                    <div className='breadcrumb-item text-white me-2'>{collectionName}</div>
                </div>
                <h1 className="text-white pt-2 fw-bold">{collectionName}</h1>
            </div>
        );
    };



    return (
        <div>
            <div className='sectionView container-fluid mt-3 px-lg-5 px-md-5 px-sm-5'>
                <Breadcrumb product={collectionItems} />
                {collectionItems.length > 0 ? (
                    <Row className="g-2">
                        {collectionItems.map((item, index) => (
                            <Col xs={6} md={6} lg={3} key={index}>
                                <ProductCard key={index} item={item} baseUrl={baseURL} wishlist={item.in_wishlist} cart={item.in_cart} />
                            </Col>
                        ))}
                    </Row>
                ) : (
                    <div>Loading....</div>
                )}
            </div>
        </div>
    );
}

export default CollectionView;
