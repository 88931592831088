import React from 'react';
import { Container } from 'react-bootstrap';
import './Home.css';
import Header from '../../components/Header/Header';
import Sliders from '../../components/Sliders/Sliders';
import OurFragrances from '../../components/OurFragrances/OurFragrances';
import Features from '../../components/Features/Features';
import Search from '../../components/Search/Search'
import Bestsellers from '../../components/Products/BestSellers';
import StoreInfo from '../../components/StoreInfo/StoreInfo';
import VideoCard from '../../components/VideoCard/VideoCard';
import ShopByGender from '../../components/ShopByGender/ShopByGender';
import Subscribe from '../../components/Subscribe/Subscribe';
import CouponCodeSlider from '../../components/CouponCodeSlider/CouponCodeSlider';
import Testimonials from '../../components/Testimonials/Testimonials';
import BlogsHome from '../../components/Blogs/BlogsHome';
import RecentlyAdded from '../../components/Products/RecentlyAdded';
const components = [
  { component: <Sliders key="Sliders" />, key: 'Sliders' },
  { component: <Bestsellers key="Bestsellers" />, key: 'Bestsellers' },
  { component: <StoreInfo key="StoreInfo" />, key: 'StoreInfo' },
  { component: <RecentlyAdded key="RecentlyAdded" />, key: 'RecentlyAdded' },
  { component: <CouponCodeSlider key="CouponCodeSlider" />, key: 'CouponCodeSlider' },
  { component: <OurFragrances key="OurFragrances" />, key: 'OurFragrances' },
  { component: <Features key="Features" />, key: 'Features' },
  { component: <VideoCard key="VideoCard" />, key: 'VideoCard' },
  { component: <ShopByGender key="ShopByGender" />, key: 'ShopByGender' },
  // { component: <Testimonials key="Testimonials" />, key: 'Testimonials' },
  { component: <Subscribe key="Subscribe" />, key: 'Subscribe' },
  { component: <BlogsHome key="BlogsHome" />, key: 'BlogsHome' },
  // Uncomment if you want to include AppIntro
  // { component: <AppIntro key="AppIntro" />, key: 'AppIntro' }
];

function Home() {
  return (
    <div className='sectionView'>
      {components.map(({ component, key }) => (
        <div key={key} data-key={key}>
          {component}
        </div>
      ))}
    </div>
  );
}

export default Home;
