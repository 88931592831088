import React, { useEffect, useState, useRef } from 'react';
import './VideoCard.css';
import { Button, Card } from 'react-bootstrap';
import { IoArrowForward, IoPlayCircle, IoPauseCircle } from 'react-icons/io5';
import axios from 'axios';

const VideoCard = () => {
    const [videoCard, setVideoCard] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [videoError, setVideoError] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true); // State to track video playback
    const videoRef = useRef(null); // Reference to the video element

    useEffect(() => {
        getVideoCard();
    }, []);

    const getVideoCard = async () => {
        try {
            const response = await axios.get('https://theattarco.com/api/client_api/videoCard/index.php');
            setVideoCard(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching video card data:', error);
            setIsLoading(false);
        }
    };

    const handleVideoError = () => {
        setVideoError(true);
    };

    const togglePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div className="video-card my-5" style={{ position: 'relative', overflow: 'hidden' }}>
            {isLoading ? (
                <p>Loading...</p>
            ) : videoCard ? (
                <>
                    {!videoError ? (
                        <video
                            ref={videoRef}
                            className="video-background"
                            autoPlay
                            loop
                            muted
                            onError={handleVideoError}
                            style={{ width: '100%', position: 'absolute', top: 0, left: 0 }}
                        >
                            <source
                                src={`https://theattarco.com${videoCard.videoUrl}`}
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <p>Video failed to load.</p>
                    )}
                    <div className="video-overlay" style={{ position: 'relative', zIndex: 1 }}>
                        <h1 className='text-black gradient-text'>{videoCard?.title}</h1>
                        <Button
                            as='Link'
                            to="/collections"
                            variant="light"
                            className='rounded-5 py-2 mt-4'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                background: 'transparent',
                                border: '4px solid #f7ef8a'
                            }}
                        >
                            <Card.Title
                                className='text-start mb-0 fw-bold'
                                style={{
                                    fontSize: '18px',
                                    color: '#f7ef8a'
                                }}
                            >
                                {videoCard?.buttonText}
                            </Card.Title>
                            <IoArrowForward
                                className="ms-5"
                                size={25}
                                color='#f7ef8a'
                            />
                        </Button>
                    </div>
                    {isPlaying ? (
                        <IoPauseCircle
                            onClick={togglePlayPause}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                zIndex: 1
                            }}
                            className="ms-5"
                            size={50}
                            color='#f7ef8a'
                        />
                    ) : (
                        <IoPlayCircle
                            onClick={togglePlayPause}
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                zIndex: 1
                            }}
                            className="ms-5"
                            size={50}
                            color='#f7ef8a'
                        />
                    )}
                </>
            ) : (
                <p>No video data available.</p>
            )}
        </div>
    );
};

export default VideoCard;
